import request from '@/utils/request'


export function getA3MeberCardList (params) {
    return request({
      url: '/api/A3Meber/CardList',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function getCard2023List (params) {
    return request({
      url: '/api/A3Meber/Card2023',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function hasDone (code) {
    return request({
      url: '/api/A3Meber/HasDone?code='+code,
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
    })
  }