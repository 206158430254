<template>
    <div class="container" ref="wrapper">
        <van-sticky>
            <van-nav-bar :title="title" left-arrow @click-left="goBack()" />
        </van-sticky>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="upLoading" :finished="finished" @load="onLoad" finished-text="没有更多了">
                <van-cell-group>

                    <van-cell v-for="item in articles" :key="item.memberId"
                        @click="toArticleDetail(item.memberId,item.amount)">

                        <van-row>
                            <van-col span="12"> 客户编号: <strong>{{ item.memberId }}</strong></van-col>
                            <van-col span="12"> 充值金额:<strong>￥{{ item.amount }}</strong></van-col>
                        </van-row>
                        <van-row>
                            <van-col span="12"> 客户姓名: {{ item.memberName }}</van-col>
                            <van-col span="12"> 剩余金额:<strong>￥{{item.balance }}</strong></van-col>
                        </van-row>
                        <van-row>
                            <van-col span="12"> 
                                <van-tag type="success"  v-if="item.memberBind>0">客户已绑定</van-tag>
<van-tag type="danger" v-else>客户未绑定</van-tag>
                            </van-col>
                            <van-col span="12"> <van-tag v-if="item.hasDone"  color="#57dd94"  size="large"
                                    text-color="#9d1d22">查看龙卡
                                </van-tag>

                                <van-tag v-else color="#9b1f24" size="large" text-color="#FFF">点击成龙卡
                                </van-tag>
                            </van-col>
                        </van-row>
                    </van-cell>
                </van-cell-group>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
  
<script>
import { getA3MeberCardList } from "@/api/a3meber";

//const key = "zn-history";
export default {
    name: "cardmeber",
    data() {
        return {
            scroll: 0,
            refreshing: false,
            productName: "",
            subTypeOption: [],
            title: "打款到账客户生成龙卡",
            keyword: "",
            upLoading: false,
            finished: false,
            articles: [],
            page: {
                current: 1,
                pageSize: 10,
                sorterType: 0,
                filterData: {

                },
            },

        };
    },
    components: {},
    activated() {
        //  window.scrollTo(0, this.scroll);
        //  alert( this.$refs.wrapper.scrollTop);
        try {
            this.$refs.wrapper.scrollTop = this.scroll;
        } catch (error) {
            console.log(error);
        }
    },
    created() {
        this.initData();
    },
    mounted() { },

    methods: {

        goBack() {
            if (this.$route.query.back) {
                this.$router.back();
            }
            else {
                this.$router.replace({ path: '/' })
            }
        },
        onRefresh() {
            this.onSearch();
            this.refreshing = false;
        },
        async initData() {
            this.page.sorterType = 0;
        },

        onSearch() {
            this.finished = false;
            this.articles = [];
            this.page.current = 1;
            this.page.pageSize = 10;
            this.onLoad();
        },
        toArticleDetail(id,amount) {

            //
            let pindex=0;
            if(amount>=100000 && amount<300000){
                pindex=1;
            }
            if(amount>=300000)
            {
                pindex=2;  
            }


            let wrapperScrollTop = this.$refs.wrapper.scrollTop;
            //存储 scrollTop 的值
            this.scroll = wrapperScrollTop;

            this.$router.push({
                path: "/question/paradetail",
                query: {id:"7ac9ea55-eeed-4ad5-b70f-371c4fd5dfcc", a3code: id,pindex:pindex},
            });
        },
        async onLoad() {
            this.upLoading = true;
            let aresult = await getA3MeberCardList({ ...this.page }); //({ ...this.page })
            this.articles.push(...aresult.data.data);
            this.upLoading = false;
            if (this.page.current * this.page.pageSize < aresult.data.total) {
                this.page.current++;
            } else {
                this.finished = true;
            }
        },
    },
};
</script>
  
<style lang="less" scoped>
.w100 {
    /deep/ img {
        width: 100%;
        max-height: 80px;
    }
}

.zntitle {
    height: 80px;
}

.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.detail {
    padding: 46px 10px 44px;

    .title {
        font-size: 18px;
        line-height: 2;
    }

    .zan {
        text-align: center;
        padding: 10px 0;

        .active {
            border-color: red;
            color: red;
        }
    }

    .author {
        padding: 10px 0;
        display: flex;
        position: sticky;
        background-color: #fff;
        top: 46px;
        z-index: 2;

        .text {
            flex: 1;
            padding-left: 10px;
            line-height: 1.5;

            .name {
                font-size: 14px;
                margin: 0;
            }

            .time {
                margin: 0;
                font-size: 12px;
                color: #999;
            }
        }
    }

    .content {
        padding: 20px 0;
        overflow: hidden;
        white-space: pre-wrap;
        word-break: break-all;

        /deep/ img {
            max-width: 100%;
            background: #f9f9f9;
        }

        /deep/ code {
            white-space: pre-wrap;
        }
    }
}
</style>
  